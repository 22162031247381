.Logo {
    background-color: white;
    padding: 5px;
    height: 50%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 100%;
}